import React, {
  ChangeEvent,
  FunctionComponent,
  MouseEvent,
  ReactElement,
  ReactNode,
  Ref,
} from 'react';
import { MessageDescriptor } from 'react-intl';
import { useMedia } from 'react-use';
import { useTheme } from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { INPUT_TYPES } from '@savgroup-front-common/constants/src/shared';
import CheckboxNaked from '@savgroup-front-common/core/src/atoms/checkbox/CheckboxNaked';
import { getFinalFieldState } from '@savgroup-front-common/core/src/atoms/Form/common/helpers/getFinalFieldState';
import { FieldMessages } from '@savgroup-front-common/core/src/atoms/Form/common/helpers/getFinalFieldState.types';
import { FIELD_STATUS } from '@savgroup-front-common/core/src/atoms/Form/common/helpers/getFinalFieldState.types';
import {
  safeFormattedIntlString,
  SafeFormattedMessage,
} from '@savgroup-front-common/core/src/formatters';
import { CheckIcon } from '@savgroup-front-common/core/src/protons/icons';
import { MessageType } from '@savgroup-front-common/types';

import {
  $ChooseProductCardCheckboxContainer,
  $ImageContainer,
  $ImageHoveredContainer,
  $PostTitle,
  $RadioCardBody,
  $RadioCardContainer,
  $RadioCardFirstLine,
  $RadioCardInfo,
  $RadioCardLabel,
  $RadioCardLabelContainer,
  $RadioCardSubLine,
  $RadioCardTitle,
  $TextContainer,
} from './RadioCardWithCheckboxColumn.styles';

export interface RadioCardWithCheckboxColumnProps {
  onChange?: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined;
  onClick?: (event: MouseEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
  isDisabled?: boolean;
  title?:
    | ReactElement
    | string
    | number
    | boolean
    | MessageType
    | MessageDescriptor;
  image?: JSX.Element;
  imageHovered?: JSX.Element;
  name: string;
  label?: string | number | MessageType;
  didactics?: string | number | MessageType;
  dataTestId?: string;
  value?: string;
  children?: ReactNode;
  errors?: FieldMessages;
  warnings?: FieldMessages;
  successes?: FieldMessages;
  isError?: boolean;
  isSuccess?: boolean;
  isWarning?: boolean;
  postTitle?: ReactNode;
  subLine?: ReactNode;
  isHidden?: boolean;
}

interface RadioCardWithRefProps extends RadioCardWithCheckboxColumnProps {
  forwardedRef?: Ref<HTMLInputElement>;
}

const RadioCardWithCheckboxColumn: FunctionComponent<
  React.PropsWithChildren<RadioCardWithRefProps>
> = ({
  isChecked = undefined,
  isDisabled = undefined,
  onClick = () => undefined,
  onChange = () => undefined,
  forwardedRef = null,
  title,
  label,
  didactics = undefined,
  children = null,
  isError = false,
  isWarning = false,
  isSuccess = false,
  errors = {},
  warnings = {},
  successes = {},
  image,
  imageHovered,
  name,
  value,
  dataTestId,
  postTitle,
  subLine,
  isHidden = false,
}) => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const theme = useTheme();
  const [status] = getFinalFieldState({
    errors: { isStatus: isError, messages: errors },
    warnings: { isStatus: isWarning, messages: warnings },
    successes: { isStatus: isSuccess, messages: successes },
    name,
  });

  return (
    <$RadioCardLabelContainer $status={status} data-testid={dataTestId}>
      <CheckboxNaked
        checked={isChecked}
        onChange={onChange}
        onClick={onClick}
        ref={forwardedRef}
        name={name}
        danger={status === FIELD_STATUS.ERROR}
        value={value}
        type={INPUT_TYPES.RADIO}
        dataTestId={dataTestId}
        isHidden={isHidden}
      />
      <$RadioCardContainer $status={status}>
        <$RadioCardFirstLine $status={status}>
          {!isMobileView && !isHidden && (
            <$ChooseProductCardCheckboxContainer isChecked={isChecked}>
              {isChecked && (
                <CheckIcon color={theme.colors.white} size="16px" />
              )}
            </$ChooseProductCardCheckboxContainer>
          )}

          <$RadioCardInfo isChecked={isChecked} isDisabled={isDisabled}>
            {image && (
              <$ImageContainer $status={status}>{image}</$ImageContainer>
            )}
            {imageHovered && (
              <$ImageHoveredContainer $status={status}>
                {imageHovered}
              </$ImageHoveredContainer>
            )}
            <$TextContainer
              title={label ? safeFormattedIntlString(label) : undefined}
              hasImage={!!image}
            >
              {title && (
                <$RadioCardTitle>{SafeFormattedMessage(title)}</$RadioCardTitle>
              )}
              {label && (
                <$RadioCardLabel>{SafeFormattedMessage(label)}</$RadioCardLabel>
              )}
              {didactics && (
                <$RadioCardLabel>
                  {SafeFormattedMessage(didactics)}
                </$RadioCardLabel>
              )}
              {postTitle && <$PostTitle>{postTitle}</$PostTitle>}
            </$TextContainer>
          </$RadioCardInfo>
          {children && (
            <$RadioCardBody hasImage={!!image}>{children}</$RadioCardBody>
          )}
        </$RadioCardFirstLine>
        {subLine && <$RadioCardSubLine>{subLine}</$RadioCardSubLine>}
      </$RadioCardContainer>
    </$RadioCardLabelContainer>
  );
};

RadioCardWithCheckboxColumn.displayName = 'RadioCardWithCheckboxColumn';

export default React.forwardRef<
  HTMLInputElement,
  RadioCardWithCheckboxColumnProps
>((props, ref) => (
  <RadioCardWithCheckboxColumn forwardedRef={ref} {...props} />
));
